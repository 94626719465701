<template>
  <div class="basic-info" v-loading="loading" element-loading-spinner="el-icon-loading">
    <p class="title">
      <span class="text">基本信息</span>
    </p>

    <div class="btns">
      <el-button
        v-if="action === 'view'"
        type="primary"
        plain
        size="mini"
        icon="el-icon-edit"
        @click="editRole"
        >修改</el-button
      >
      <el-button
        v-if="action === 'edit'"
        type="primary"
        plain
        size="mini"
        icon="el-icon-check"
        :loading="saving"
        @click="saveEdit"
      >
        保存
      </el-button>
      <el-button
        v-if="action === 'edit'"
        type="danger"
        plain
        size="mini"
        icon="el-icon-close"
        @click="cancelEdit"
        >取消</el-button
      >
      <el-button
        type="warning"
        plain
        size="mini"
        :loading="disabling"
        icon="el-icon-close"
        @click="disableRole"
      >
        停用
      </el-button>

      <el-button
        type="success"
        plain
        size="mini"
        :loading="enabling"
        icon="el-icon-check"
        @click="enableRole"
      >
        启用
      </el-button>
    </div>

    <el-form
      v-if="!loading"
      :model="roleFormData"
      inline
      label-suffix=":"
      size="mini"
    >
      <el-form-item label="角色名">
        <span v-if="action === 'view'">
          {{ roleData.roleName }}
        </span>
        <el-input v-else v-model="roleFormData.roleName" clearable />
      </el-form-item>
      <el-form-item label="编码">
        <span v-if="action === 'view'">
          {{ roleData.roleCode }}
        </span>
        <el-input v-else v-model="roleFormData.roleCode" clearable />
      </el-form-item>
      <el-form-item label="等级">
        <span v-if="action === 'view'">
          {{ roleData.roleLevel }}
        </span>
        <el-input v-else v-model="roleFormData.roleLevel" clearable />
      </el-form-item>
      <el-form-item label="状态">
        <span :style="`color:${roleData.flag === 0 ? 'red' : ''}`">
          {{ roleData._caption.flag }}
        </span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "basic-role-info",
  props: {
    roleId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      saving: false,
      disabling: false,
      enabling: false,
      action: "view",
      roleData: {},
      roleFormData: {},
    };
  },
  methods: {
    async enableRole() {
      try {
        this.enabling = true;
        await this.$http.doApi("fw-cms-role-enable", {
          id: this.roleId,
        });
        this.$message.success("启用成功");
        this.$emit("changed");
        this.loadRoleInfo(this.roleId);
      } finally {
        this.enabling = false;
      }
    },
    async disableRole() {
      try {
        this.disabling = true;
        await this.$http.doApi("fw-cms-role-disable", {
          id: this.roleId,
        });
        this.$message.success("停用成功");
        this.$emit("changed");
        this.loadRoleInfo(this.roleId);
      } finally {
        this.disabling = false;
      }
    },
    async editRole() {
      this.action = "edit";
      this.roleFormData = { ...this.roleData };
    },
    cancelEdit() {
      this.roleFormData = {};
      this.action = "view";
    },
    async saveEdit() {
      try {
        this.saving = true;
        await this.$http.doApi("fw-cms-role-save", {}, this.roleFormData);
        this.action = "view";
        this.loadRoleInfo(this.roleId);
        this.$emit("changed");
      } catch (e) {
        console.log(e);
      } finally {
        this.saving = false;
      }
    },
    async loadRoleInfo(id) {
      try {
        this.roleData = await this.$http.doApi("fw-cms-role-get", {
          id,
          wrapper: true,
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    roleId: {
      immediate: true,
      handler(val) {
        this.loadRoleInfo(val);
      },
    },
  },
};
</script>

<style lang="less">
.basic-info {
  .title {
    border-bottom: 2px dotted #cccccc;
    padding: 0.1rem 0.5rem;

    .text {
      font-weight: 600;
    }
  }

  .btns {
    padding-bottom: 0.8rem;
  }
}
</style>